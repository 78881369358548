.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  height: 100%;
  background-color: $white;

  padding: 0 $buildingUnit;

  @include min-tablet {
    padding: 0 $buildingUnit * 10;
  }

  @include min-desktop-hd {
    padding: 0 $buildingUnit * 20;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.cdk-overlay-pane {
  height: auto !important;
}

input[type="checkbox"] {
  accent-color: black;
}

input[type="radio"] {
  accent-color: black;
}

.mat-calendar-next-button.mat-icon-button.mat-button-base,
.mat-calendar-previous-button.mat-icon-button.mat-button-base {
  color: $white;
}
