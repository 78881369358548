.form {
  &.submitted {
    .input.ng-pristine.ng-invalid {
      border: 1px solid $error;
      color: $error;
    }
  }
}

.input {
  padding: $buildingUnit;
  border: 1px solid $pargoGrey;
  border-radius: $buildingUnit * 0.25;
  width: 100%;
  margin: 0;

  font-family: "Open Sans";
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;

  &.ng-invalid.ng-touched {
    border: 1px solid $error;
    color: $error;
  }

  &:focus {
    outline: none;
    border: 1px solid $darkYellow;
  }
}

.input__text {
  @extend .input;
}

.input__text + .input__error {
  margin-bottom: $buildingUnit * 2;
}

.input__error {
  font-size: 10px;
  display: flex;
  padding: $buildingUnit*0.5;
  margin: $buildingUnit*0.5 0 0 0;
  line-height: $baseLineHeight;
  color: $errorFont;
  background-color: $errorBackground;
  border-radius: $buildingUnit * 0.25;
}

.select {
  @extend .input;
  height: $buildingUnit * 4;
}

label {
  font-family: "Roboto", "Helvetica" !important;
  font-size: $baseFontSize * 0.9;
  line-height: $baseLineHeight;

  display: flex;
  flex-direction: column;

  font-weight: 700;
}
