@import "~driver.js/dist/driver.min.css";

.layout__header.driver-fix-stacking {
  position: relative !important;
}

div#driver-popover-item .driver-popover-title {
  font-family: "futura-pt", "Nunito";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: $black;
  text-transform: uppercase;
}

div#driver-popover-item .driver-popover-description {
  font-family: "Roboto", "Open Sans", "futura-pt", "Nunito";
  color: #414141;
  font-size: 16px;
}

div#driver-popover-item .driver-popover-footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $buildingUnit * 0.25 $buildingUnit * 0.5;
  text-transform: uppercase;

  border-radius: $buildingUnit * 0.25;
  border: 1px solid $pargoBlue;
  color: $white;
  margin: $buildingUnit 0;
  background-color: $pargoBlue;

  font-family: "futura-pt", "Nunito";
  font-weight: 700;
  font-size: $baseFontSize * 0.8;
  line-height: $baseLineHeight;
  text-shadow: none;

  outline: none;
}

div#driver-popover-item .driver-popover-footer button.driver-disabled {
  display: none !important;
}

div#driver-popover-item {
  min-width: 300px;
  max-width: 550px;
}
