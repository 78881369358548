//@import "./variables";

svg {
  width: 100%;
  height: 100%;
}

// Date picker icon hack
svg.mat-datepicker-toggle-default-icon {
  height: $buildingUnit * 2;
  width: $buildingUnit * 2;
}
