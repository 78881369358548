@import "@angular/cdk/overlay-prebuilt.css";

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.mat-datepicker-content {
  background: $white;
}

.mat-calendar {
  border: 1px solid $black;
  border-radius: 0 0 $buildingUnit * 0.25 $buildingUnit * 0.25;
  border-top: none;
}

div.mat-calendar-header {
  background: $black;
  color: $white;
  padding: 0;
}

button.mat-calendar-period-button {
  padding: 0 $buildingUnit * 0.5;
  text-decoration: none;
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
  margin-left: $buildingUnit * 0.5;
}

.mat-calendar-table {
  margin: $buildingUnit 0;
}

.mat-calendar-body-label {
  display: none;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: inherit !important;
}

.mat-calendar-body-cell-content:hover {
  background-color: $yellow !important;
  color: $black !important;
}

.mat-calendar-body-semi-selected {
  background: $white !important;
  opacity: 1 !important;
  color: $black !important;
  border-color: 1px solid $black !important;
}

.mat-calendar-body-in-range::before {
  background-color: $yellow !important;
}

.cdk-visually-hidden {
  display: none;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  background-color: transparent;
}

.mat-mdc-button.mat-unthemed {
  color: white !important;
}

.mat-calendar-controls,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-content .mat-calendar-next-button {
  color: #fff !important;
}

.mat-calendar-arrow {
  fill: #fff !important;
}

.mat-calendar-controls {
  margin: 0 !important;
}

.mat-calendar-body-selected {
  background-color: $black !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: $yellow !important;
  color: $black !important;
}
