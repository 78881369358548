$buildingUnit: 12px;

$unit: 8px;

$baseFontSize: 0.8rem;
$baseLineHeight: 1.2;
$scaleFactor: 1.05;

$notificationIndex: 1500;
$headerIndex: 1500;
