$darkYellow: #f9cf00;
$yellow: #fff200;
$yellowBackground: rgba(255, 242, 0, 0.15);

$darkYellowBackground: rgba(194, 184, 4, 0.534);

$pargoBlue: #1d7cbe;
$pargoLightBlue: #e6f2ff;
$pargoBlueSelected: #3c8ac1;

$pargoGrey: #505050;
$pargoMediumGrey: #d3d3d3;
$pargoLightGrey: #f0f0f0;

$white: #ffffff;
$black: #000000;
$grey: #656565;
$greyLight: #e8e8e8;
$greyLighter: #f2f2f2;

$grey1: #cccccc;
$grey2: #4a4a4a;

$inputFont: #414141;

$success: #2bba42;
$successBackground: #d7ffde;

$errorFont: #ce0000;
$error: #ce0000;
$errorBackground: #ffdada;

$warning: #f2711c;
$warningBackground: #fad0b5;

$infoFont: $grey;
$info: $grey;
$infoBackground: $yellowBackground;
$infoBorder: $darkYellow;

$disabled: #a4a4a4;
$disabledBackground: #e8e8e8;
