.ct-series-a {
  .ct-slice-donut {
    stroke: $yellow;
    fill: $white;
  }

  .ct-line {
    stroke: $black;
  }

  .ct-point {
    stroke: $yellow;
  }

  .ct-bar {
    stroke: $yellow;
  }
}

.ct-chart-donut {
  .ct-label {
    display: none;
  }
}

.ct-chart-line {
  .ct-label {
    //transform: scale(0.8);
    color: $black;
  }
}

.ct-chart-bar {
  .ct-label {
    //transform: scale(0.8);
    font-size: 0.55rem;
    color: $black;
  }
}
